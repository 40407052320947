import React, { useState } from "react";
import classNames from "classnames/bind";
import catA from "../../../media/cata.jpg";
import catB from "../../../media/catb.jpg";

import styles from "./cat.module.scss";

const cx = classNames.bind(styles);

export const Cat = () => {
  const [cat, setCat] = useState(catA);

  return (
    <img
      className={cx("cat-image")}
      onMouseOver={() => setCat(catB)}
      onMouseOut={() => setCat(catA)}
      src={cat}
      alt="meow"
    />
  );
};
